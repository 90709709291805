body {
  background-color: #101010;
  color: #F0F0F0;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Header */
.navbar-nav .nav-item a, .nav-link {
  color: #F0F0F0 !important;
  font-weight: 600;
}

.navbar-toggler-icon {
  background-image: url("../public/images/list.svg") !important;
}

.nav-toggle-btn {
  box-shadow: none !important;
}


/* Page */
.page {
  min-height: 730px;
}

/* Promo */
.promo-item {
  color: #bfbfbf;
}

/* Footer */
.footer-links {
  list-style: none;
}

.footer-links a, .footer-links p {
  color: #676767;
  text-decoration: none;
  margin-right: 10px;
  margin-left: 10px;
}

/* Modal */
.modal-header{
  border-bottom: 0;
}

.modal-content {
  background-color: #272727;
}

form button {
  margin: 12 !important;
  border: 0 !important;
  background-color: #2e2e2e !important;
}

/* - */
.img-download {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

/* Markdown */
.markdown h1, .markdown h2 {
  margin-top: 50pt;
  font-weight: bold;
}

.markdown h3 {
  margin-top: 15pt;
}